.filter-container {
  width: auto;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.container {
  width: auto;
  display: flex;
  justify-content: center;
}

.row {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  max-width: 88rem;
}

@media (max-width: 703px) {
  .container {
    width: auto;
    display: flex;
    justify-content: center;
  }

  .row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 88rem;
  }
}
