.card {
  background-color: #1e1e1e;
  width: 13rem;
  height: 12rem;
  border: 1px solid #ccc; /* 테두리 */
  border-radius: 8px; /* 둥근 모서리 */
  font-family: "BMJUA";
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  margin: 10px;
}

.card:hover {
  background-color: #1e1e1e;
}

.item-detail-img {
  height: 4rem;
  width: 4rem;
  margin: 1rem;
  /* margin-bottom: 1rem; */
}

@media (max-width: 1694px) {
  .card {
    background-color: #1e1e1e;
    width: 17rem;
    height: 10rem;
    border: 1px solid #ccc; /* 테두리 */
    border-radius: 8px; /* 둥근 모서리 */
    padding: 10px; /* 내부 여백 */
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
