.item-card {
  background-color: #27272a;

  margin-bottom: 20px;
  padding: 5px;
}

.item-image {
  padding: 5%;
  margin-right: 1rem;
  width: 90px;
  height: 90px;
}

.item-text {
  color: white;
  padding: 5%;
  display: flex;
  font-family: BMJUA;
  flex-direction: column;
  justify-content: flex-end;
  text-align: left;
}

.item-card-button:hover {
  background-color: rgb(77, 77, 77);
}

.card-container {
  display: flex;
  width: 20rem;
  margin: 1rem;
  background-color: #27272a;
  border-radius: 10px;
}
