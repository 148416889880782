.container {
  width: auto;
  display: flex;
  justify-content: center;
}

.row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 88rem;
}

.filters {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin: 1rem 0;
  height: 100px;
}

.filters label {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.1rem;
  margin-left: 0.1rem;
}

@media (max-width: 768px) {
  .filters-mobile-container {
    display: flex;
    flex-direction: column;

    align-items: center;
  }

  .filters {
    display: flex;
    gap: 1rem;
    margin: 3rem 0;
    height: 200px;
    width: 250px;
    flex-wrap: wrap;
  }

  .filters label {
    display: flex;
    flex-direction: row;
    font-size: 1.1rem;
    margin-left: 0.1rem;
  }
}
