.ulabel {
  font-size: x-large;
  margin: 0;
  margin-top: 2rem;
}

.div-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.div-flex-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  margin: 2rem;
}

.rank-button {
  font-family: "BMZUA";
  background-color: #0842a0;
  font-size: large;
}

.rank-button:hover {
  background-color: darkblue;
}

.rank-button:disabled {
  background-color: gray;
}

.nickname-input[type="text"] {
  width: 10rem;
  height: 3rem;
  margin-right: 1rem;
}

.upgrade-label {
  font-size: x-large;
  margin-top: 2rem;
}

.upgrade-button {
  display: none;
}

.reset-button {
  display: none;
}

@media (max-width: 768px) {
  .upgrade-button {
    margin-top: 1rem;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    margin-left: 1rem;
    margin-top: 9px;
    padding: 15px;
    width: 8rem;
    height: 3rem;
    display: block;
    text-align: center;
    font-size: xx-large;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-user-select: none; /* Safari, Chrome */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* 표준 */
  }

  .reset-button {
    margin-top: 1rem;

    background-color: transparent;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 2rem;
    display: block;
    font-size: 45px;
    padding: 0;
    margin: 0;
    width: 8rem;
    text-align: center;
  }

  .reset-button:hover {
    background-color: transparent;
  }

  .upgrade-button:hover {
    background-color: #4caf50;
  }
}

@media (max-width: 1920px) {
  .div-flex {
    font-size: small;
  }

  .nickname-input[type="text"] {
    width: 10rem;
    height: 40px;
    margin-right: 1rem;
  }

  .rank-button {
    font-size: 13px;
  }
}
