.mob-detail-card-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin: 2rem;
  padding: 1rem;
}

.mob-detail-img-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* max-height: 15rem; */
}

.mob-detail-card-item-card-button {
  padding: 0;
  margin: 0;
  width: fit-content;
  height: fit-content;
  background-color: transparent;
}

.mob-detail-card-item-card-button:hover {
  background-color: transparent;
}

.mob-detail-img {
  /* height: 7rem;
  width: 7rem; */
}

.item-detail-img {
  height: 4rem;
  width: 4rem;
  margin: 1rem;
  /* margin-bottom: 1rem; */
}

.text-color-pink {
  color: pink;
}

.radius-container-box {
  border-radius: 15px;
  background-color: #272727;
  padding: 1rem;
  margin: 4rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.radius-small-box {
  border-radius: 15px;
  padding: 10px;
  margin: 1rem;
  text-align: center;
  width: 8rem;
}

.flex-row {
  margin: 0;
  padding: 0;
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.center {
  display: flex;
  justify-content: center;
  text-align: start;
}

.red {
  background-color: #b12929;
}

.light-green {
  background-color: #487f2d;
}

.yellow {
  background-color: #bf9000;
}

.pink {
  background-color: #453080;
}

.mob-detail-card-row1 {
  width: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mob-detail-card-row2 {
  width: 74rem;
  display: flex; /* 수평으로 나열 */
  flex-direction: row; /* 세로 방향으로 정렬 */
  gap: 5px; /* 카드 사이의 간격 */
  flex-wrap: wrap;
}

.card {
  background-color: #1e1e1e;
  width: 13rem;
  height: 12rem;
  border: 1px solid #ccc; /* 테두리 */
  border-radius: 8px; /* 둥근 모서리 */
  font-family: "BMJUA";
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  margin: 10px;
}

@media (max-width: 1694px) {
  .mob-detail-card-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem;
    padding: 1rem;
  }
  .mob-detail-card-row1 {
    width: 30rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .mob-detail-card-row2 {
    margin-top: 2rem;
    width: 70rem;
    display: flex; /* 수평으로 나열 */
    flex-direction: column; /* 세로 방향으로 정렬 */
    gap: 10px; /* 카드 사이의 간격 */
    flex-wrap: wrap;
    align-items: center;
  }

  .card {
    background-color: #1e1e1e;
    width: 17rem;
    height: 10rem;
    border: 1px solid #ccc; /* 테두리 */
    border-radius: 8px; /* 둥근 모서리 */
    padding: 10px; /* 내부 여백 */
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
