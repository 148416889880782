.right {
  margin-top: 0;
  margin-left: 10rem;
  width: 15rem;
}

.left {
  margin-right: 10rem;
  margin-top: 3rem;
  width: 15rem;
}

.card-center {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.left-title {
  color: blueviolet;
}

.rank-ul {
  font-size: x-large;
}

.rank-li {
  margin-top: 1rem;
}

.upgrade-container {
  display: flex;
  justify-content: center;
  margin: auto;
  width: 95%;
  height: 60%;
  background-color: #171718;
  padding: 4rem;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .upgrade-container {
    display: flex;
    justify-content: center;
    flex-direction: column;

    margin: 0;
    width: auto;
    height: auto;
    background-color: transparent;
    padding: 0rem;
    border-radius: 0px;
  }

  .card-center {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .right {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-bottom: 2rem;
  }

  .left {
    margin: 0;
    display: none;
  }

  .left-title {
    color: blueviolet;
  }

  .rank-ul {
    font-size: xx-large;
  }

  .rank-li {
    margin-top: 1rem;
    justify-content: center;
  }
}
