footer {
  width: 100%;
  bottom: 20px;
  padding-top: 15px;
  color: #808080;
  font-size: 11px;
  align-content: center;

  height: 70px;
  position: relative;
  /* transform: translateY(-110%); */
}

footer a {
  display: inline-block;
  margin: 20px 20px 0px 20px;
  padding: 10;
  color: #808080;
  font-size: 11px;
}

footer a:visited {
  color: #808080;
}

footer p span {
  margin-top: 10px;
  display: block;
  text-align: center;
  font-size: 12px;
}
